import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

//import Scrollbar from 'react-smooth-scrollbar';
//import ProtectedRoute from './protected';
import Loader from '../components/loader';
import { Path } from './routes';

const NotFound = lazy(() => import('../pages/404'));
const Home = lazy(() => import('../pages/home'));
const WaitList = lazy(() => import('../pages/waitlist'));
const Login = lazy(() => import('../pages/payadminlogin'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const Merchant = lazy(() => import('../pages/merchant'));
const MerchantLogin = lazy(() => import('../pages/merchant/login'));
const MerchantSignup = lazy(() => import('../pages/merchant/sign-up'));
const MerchantForgotPassword = lazy(() => import('../pages/merchant/forgot-password'));
const MerchantResetPassword = lazy(() => import('../pages/merchant/reset-password'));

const MerchantDashboard = lazy(() => import('../pages/merchant/dashboard'));
const MerchantReceivePayment = lazy(() => import('../pages/merchant/dashboard/receive-payment'));
const MerchantWalletDashboard = lazy(() => import('../pages/merchant/dashboard/wallet'));
const MerchantInventoryDashboard = lazy(() => import('../pages/merchant/dashboard/inventory'));
const MerchantInventoryDashboardProductDetail = lazy(() => import('../pages/merchant/dashboard/inventory/product'));
//MerchantInventoryDashboardProductDetail
const MerchantTransactionDashboard = lazy(() => import('../pages/merchant/dashboard/transaction'));
const MerchantPaymentDashboard = lazy(() => import('../pages/merchant/dashboard/payment'));
const MerchantReportDashboard = lazy(() => import('../pages/merchant/dashboard/report'));
const MerchantTeamDashboard = lazy(() => import('../pages/merchant/dashboard/team'));
const MerchantSettingDashboard = lazy(() => import('../pages/merchant/dashboard/msettings'));
const MerchantSupportDashboard = lazy(() => import('../pages/merchant/dashboard/support'));



const RouterConfig = () => {
   // const auth = useSelector((state: RootState) => state.auth);

    return (
        <Suspense fallback={<Loader />}>
          {/* <Scrollbar
            damping={0.07}
            renderByPixels={true}
            //alwaysShowTracks={true}
            plugins={{
              overscroll: { effect: "bounce", damping: 0.12 }
            }}
            style={{
                width: '100%',
                height: '100vh', 
                overflow: 'hidden', 
                margin: 0, 
                padding: 0, 
            }}
            //trackXProps={{ style: { backgroundColor: 'transparent' } }}  // Optional: Hide track color
            //trackYProps={{ style: { backgroundColor: 'transparent' } }}  // Optional: Hide track color
            //thumbXProps={{ style: { backgroundColor: '#26680C' } }}  // Set the horizontal scrollbar thumb color to green
            //thumbYProps={{ style: { backgroundColor: '#26680C' } }}  // Set the vertical scrollbar thumb color to green
          
          > */}
            <Routes>

                <Route path={Path.Home} element={<Home />} />
                <Route path={Path.WaitList} element={<WaitList />} />
                <Route path={Path.Login} element={<Login />} />
                <Route path={Path.Dashboard} element={<Dashboard />} />
                <Route path={Path.Merchant} element={<Merchant />} />
                <Route path={Path.MerchantLogin} element={<MerchantLogin />} />
                <Route path={Path.MerchantSignup} element={<MerchantSignup />} />
                <Route path={Path.MerchantForgotPassword} element={<MerchantForgotPassword />} />
                <Route path={Path.MerchantResetPassword} element={<MerchantResetPassword />} />
                
                <Route path={Path.MerchantDashboard} element={<MerchantDashboard />} />
                <Route path={Path.MerchantReceivePayment} element={<MerchantReceivePayment />} />
                <Route path={Path.MerchantWalletDashboard} element={<MerchantWalletDashboard />} />
                <Route path={Path.MerchantInventoryDashboard} element={<MerchantInventoryDashboard />} />
                <Route path={Path.MerchantInventoryDashboardProductDetail} element={<MerchantInventoryDashboardProductDetail />} />
                <Route path={Path.MerchantTransactionDashboard} element={<MerchantTransactionDashboard />} />
                <Route path={Path.MerchantPaymentDashboard} element={<MerchantPaymentDashboard />} />
                <Route path={Path.MerchantReportDashboard} element={<MerchantReportDashboard />} />
                <Route path={Path.MerchantTeamDashboard} element={<MerchantTeamDashboard />} />
                <Route path={Path.MerchantSettingsDashboard} element={<MerchantSettingDashboard />} />
                <Route path={Path.MerchantSupportDashboard} element={<MerchantSupportDashboard />} />
                
                <Route path="*" element={<NotFound />} />
                
            </Routes>
          {/* </Scrollbar> */}
        </Suspense>
    );
};

export default RouterConfig;
